<template>
  <div id="the-nav-bar" class="container mx-auto px-2 sm:px-0">
    <div
      class="flex flex-col-reverse justify-between mb-8 md:flex-row md:mt-8 md:mb-16"
    >
      <div>
        <a
          @click="navTo('About')"
          :class="[
            activeIndex === 'About'
              ? 'border-b-4 border-green-500'
              : 'hover:text-gray-500',
            'text-lg font-bold duration-200 py-4'
          ]"
          href="#"
          >Bienvenida</a
        >
        <a
          @click="navTo('Portafolio')"
          :class="[
            activeIndex === 'Portafolio'
              ? 'border-b-4 border-green-500'
              : 'hover:text-gray-500',
            'text-lg font-bold duration-200 py-4 mx-8'
          ]"
          href="#"
          >Portafolio</a
        >
      </div>
      <div class="flex flex-row justify-end md:flex-none">
        <a
          class="text-xl font-medium hover:text-gray-500 duration-200 py-4 mx-1"
          href="https://twitter.com/hleonardoms_"
          target="_blank"
        >
          <i class="lab la-twitter"></i>
        </a>
        <a
          class="text-xl font-medium hover:text-gray-500 duration-200 py-4 mx-1"
          href="https://www.instagram.com/hleonardoms_"
          target="_blank"
        >
          <i class="lab la-instagram"></i>
        </a>
        <a
          class="text-xl font-medium hover:text-gray-500 duration-200 py-4 mx-1"
          href="https://github.com/hleonardoms"
          target="_blank"
        >
          <i class="lab la-github"></i>
        </a>
        <a
          class="text-xl font-medium hover:text-gray-500 duration-200 py-4 mx-1"
          href="https://www.linkedin.com/in/hleonardoms"
          target="_blank"
        >
          <i class="lab la-linkedin-in"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheNavBar',
  data() {
    return {
      isShowingMenu: false,
      activeIndex: 'About'
    }
  },
  methods: {
    showMenu() {
      this.isShowingMenu = !this.isShowingMenu
    },
    async navTo(val) {
      const routeName = this.$route.name
      if (routeName !== val) {
        this.activeIndex = val
        await this.$router.push({
          name: val
        })
      }
    }
  }
}
</script>

<style></style>
