<template>
  <div id="app" class="flex flex-col">
    <the-nav-bar></the-nav-bar>
    <router-view />
  </div>
</template>

<script>
import TheNavBar from '@/components/ui/TheNavBar'

export default {
  name: 'App',
  components: {
    TheNavBar
  }
}
</script>

<style></style>
