<template>
  <div id="about">
    <div
      class="flex items-center flex-col md:flex-row justify-between container mx-auto mt-8 md:mt-0 px-2 md:px-0"
    >
      <div class="flex-1 xs:my-16">
        <div class="flex flex-col">
          <div class="text-6xl font-black">
            Leonardo Segura
          </div>
          <div class="text-green-500 text-xl font-bold mt-2">
            Ingeniero &bull; Web Developer &bull; Mobile Developer
          </div>
          <div class="text-white0 font-medium mt-4 md:mt-8">
            Desarrollador web y móvil con enfoque en JavaScript, Vue.js,
            Android, Java, PHP, Kotlin y Flutter.
          </div>
          <div class="flex flex-col md:flex-row my-8 md:my-16">
            <div class="flex align-middle justify-center">
              <a
                href="#"
                class="bg-green-500 hover:bg-green-400 focus:ring focus:ring-green-300 duration-200 text-white font-bold rounded px-10 py-3"
              >
                CONOCE MI TRABAJO
              </a>
            </div>
            <div class="flex align-middle justify-center my-4 md:my-0">
              <a
                href="https://github.com/hleonardoms"
                target="_blank"
                class="text-blue-500 font-medium no-underline hover:underline px-10 py-3"
              >
                GitHub <i class="lab la-github"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-1 hidden md:block">
        <div class="flex justify-center">
          <img src="../assets/svg/web_coding.svg" class="h-96 mt-8 mb-16" />
        </div>
      </div>
    </div>
    <div class="bg-gray-100 py-16">
      <div class="flex flex-row justify-between container mx-auto px-2 md:px-0">
        <div class="flex text-center md:text-left">
          <div class="flex flex-col">
            <div class="text-4xl md:text-6xl font-black items-stretch">
              Acerca de mí
            </div>
            <div class="text-gray-600 font-medium my-6">
              Actualmente trabajo como full stack developer creando aplicaciones
              web para diversos ámbitos de gestión empresarial, como profesional
              me encanta estar en constante actualización sobre tecnologías
              relacionadas al desarrollo web y móvil.
              <br />
              Me encanta aplicar mejoras visuales y de funcionamiento a
              aplicaciones, así como hacer uso de buenas prácticas al momento de
              programar, soy muy dedicado en mis desarrollos buscando la mejor
              experiencia para el usuario.
              <br />
              En mis ratos libres suelo practicar código, experimentar con
              aplicaciones y jugar videojuegos.
            </div>
            <div class="flex flex-row mt-4 justify-center md:justify-start">
              <a
                href="https://www.linkedin.com/in/hleonardoms"
                target="_blank"
                class="bg-blue-500 hover:bg-blue-400 focus:ring focus:ring-blue-300 duration-200 text-white font-bold rounded px-10 py-3 mb-4"
              >
                VISITAR MI LINKEDIN
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-between container py-8 mx-auto">
      <div class="flex-1">
        <div class="flex flex-col">
          <div class="text-4xl md:text-6xl font-black text-center mt-8">
            Conocimientos
          </div>
          <div class="grid grid-cols-3 gap-4 text-gray-800 text-center my-16">
            <div class="text-5xl md:text-8xl">
              <i class="lab la-git"></i>
            </div>
            <div class="text-5xl md:text-8xl">
              <i class="lab la-github"></i>
            </div>
            <div class="text-5xl md:text-8xl">
              <i class="lab la-html5"></i>
            </div>
            <div class="text-5xl md:text-8xl">
              <i class="lab la-css3"></i>
            </div>
            <div class="text-5xl md:text-8xl">
              <i class="lab la-js-square"></i>
            </div>
            <div class="text-5xl md:text-8xl">
              <i class="lab la-vuejs"></i>
            </div>
            <div class="text-5xl md:text-8xl">
              <i class="lab la-java"></i>
            </div>
            <div class="text-5xl md:text-8xl">
              <i class="lab la-php"></i>
            </div>
            <div class="text-5xl md:text-8xl">
              <i class="lab la-android"></i>
            </div>
            <div class="text-5xl md:text-8xl">
              <i class="lab la-linux"></i>
            </div>
            <div class="text-5xl md:text-8xl">
              <i class="las la-database"></i>
            </div>
            <div class="text-5xl md:text-8xl">
              <i class="las la-server"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-center bg-gray-800 text-center py-8">
      <div class="container text-white">
        &copy; hleonardoms.com <br />
        Hecho con Vue.js, Tailwind CSS y ❤
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  title: 'Bienvenida',
  components: {}
}
</script>
