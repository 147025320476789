var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mx-auto px-2 sm:px-0",attrs:{"id":"the-nav-bar"}},[_c('div',{staticClass:"flex flex-col-reverse justify-between mb-8 md:flex-row md:mt-8 md:mb-16"},[_c('div',[_c('a',{class:[
          _vm.activeIndex === 'About'
            ? 'border-b-4 border-green-500'
            : 'hover:text-gray-500',
          'text-lg font-bold duration-200 py-4'
        ],attrs:{"href":"#"},on:{"click":function($event){return _vm.navTo('About')}}},[_vm._v("Bienvenida")]),_c('a',{class:[
          _vm.activeIndex === 'Portafolio'
            ? 'border-b-4 border-green-500'
            : 'hover:text-gray-500',
          'text-lg font-bold duration-200 py-4 mx-8'
        ],attrs:{"href":"#"},on:{"click":function($event){return _vm.navTo('Portafolio')}}},[_vm._v("Portafolio")])]),_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row justify-end md:flex-none"},[_c('a',{staticClass:"text-xl font-medium hover:text-gray-500 duration-200 py-4 mx-1",attrs:{"href":"https://twitter.com/hleonardoms_","target":"_blank"}},[_c('i',{staticClass:"lab la-twitter"})]),_c('a',{staticClass:"text-xl font-medium hover:text-gray-500 duration-200 py-4 mx-1",attrs:{"href":"https://www.instagram.com/hleonardoms_","target":"_blank"}},[_c('i',{staticClass:"lab la-instagram"})]),_c('a',{staticClass:"text-xl font-medium hover:text-gray-500 duration-200 py-4 mx-1",attrs:{"href":"https://github.com/hleonardoms","target":"_blank"}},[_c('i',{staticClass:"lab la-github"})]),_c('a',{staticClass:"text-xl font-medium hover:text-gray-500 duration-200 py-4 mx-1",attrs:{"href":"https://www.linkedin.com/in/hleonardoms","target":"_blank"}},[_c('i',{staticClass:"lab la-linkedin-in"})])])}]

export { render, staticRenderFns }