<template>
  <div id="contact">
    <div class="flex flex-row justify-between container my-20 mx-auto">
      <div class="flex">
        <div class="flex flex-col">
          <div class="text-6xl font-black items-stretch">
            Portafolio de proyectos
          </div>
          <div class="text-gray-600 font-medium my-6">
            En construcción...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Portafolio',
  title: 'Portafolio'
}
</script>

<style></style>
